.background {
  width: 250px;
  height: 250px;
  filter: contrast(200%) brightness(150%);
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 1),
      rgba(255, 255, 255, 0)
    ),
    url("data:image/svg+xml,%3Csvg viewBox='0 0 382 382' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='2.21' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
}
