.navigation {
    display: flex;
    justify-content: right;
    text-align: center;
    padding: 1rem;
    width: 100%;
    background-color: #f0f0f0;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    
    

  }
  
  .navigation__list {
    list-style: none;
    margin: 0;
    padding-right: 30px;
    display: flex;
    opacity: 100%;
  }
  
  .navigation__item {
    margin: 0 0.5rem;
    

  }
  
  .navigation__item a {
    text-decoration: none;
    color: #333;
    padding: 0.5rem 1rem;
    
  }
  
  .navigation__item a:hover {
    color: #555;
    background-color: #ddd;
    
  }