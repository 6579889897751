.contact {
  margin: 0 auto;
  padding: 3rem 0;
  background-color: #f0f0f0;
  height: 100vh;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    justify-content: center;
    width: 50%;

    label {
      margin-bottom: 10px;
    }

    input {
      margin-bottom: 20px;
      padding: 10px;
      resize: vertical;
      justify-content: center;
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;

      input {
        width: 100%;
        font-size: 16px;
      }
      textarea {
        width: 100%;
        font-size: 16px;
      }
    }

    textarea {
      margin-bottom: 20px;
      padding: 10px;
      height: 100px;
      resize: vertical;
      justify-content: center;
    }

    button[type="submit"] {
      background-color: #333;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
