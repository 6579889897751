.portfolio {
    padding: 3rem 0;
    background-color: #f0f0f0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .portfolio__title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .portfolio__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    
  }
  
  .portfolio__item {
    margin-top: 1rem;
    flex: 0 0 calc(33.33% - 2rem);
    text-align: center;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 2rem;
    background-color: white;
  }
  
  @media screen and (max-width: 768px) {
    .portfolio__items {
      flex-direction: column;
      padding-right: 10px;
      padding-left: 10px;
    }
  
    .portfolio__item {
      flex: 0 0 100%;
    }
  }
  
  
  
  .portfolio__item-image {
    margin-top: 1rem;
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    border-radius: 4px;
    margin-bottom: 1rem;
    border: 1px;
    
    
  }
  .portfolio__item_image-multiple {
    display: flex;
    justify-content: center;
    
  }
  
  .portfolio__item-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .portfolio__item-description {
    color: #666;
    margin-bottom: 1rem;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .portfolio__item-link {
    display: inline-block;
    background-color: #333;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
  }
  
  .portfolio__item-link:hover {
    background-color: #555;
  }
  
  .skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  
    .skill {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .skill-icon {
        font-size: 48px;
      }
    }
  }