.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.home__content {
  text-align: center;
}

.home__title {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.home__subtitle {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 2rem;
}

.home__cta {
  display: inline-block;
  background-color: #333;
  color: #fff;
  padding: 100px;
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
}

.home__cta:hover {
  background-color: #555;
}

.skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 20px;
    padding: 50px;
  
    .skill {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .skill-icon {
        font-size: 48px;
      }
    }
  }