.footer {
    padding: 20px 0;
    text-align: left;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f0f0f0;
  
    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
  
      span {
        margin-right: 10px;
      }
  
      .footer__link {
        color: #000;
        font-size: 24px;
        transition: color 0.3s;
  
        &:hover {
          color: #0366d6;
        }
      }
  
      .footer__icon {
        vertical-align: middle;
        padding-right: 10px;
      }
    }
  }
  